// @flow

import React from 'react';
import { connect } from 'react-redux';
import { getRelationship } from '../../selectors';
import Image from '../Image';

type Props = {
    organisation: Object,
    logo: Object,
    maxWidth?: string,
    maxHeight?: string,
    style?: Object,
}

const OrganisationLogo = ({ organisation, logo, maxWidth = '150px', maxHeight = '70px', style = {} }: Props) => {
    if (!logo || !logo.links.thumbnail) {
        return null;
    }

    return (
        <Image
            url={logo.links.thumbnail.href}
            alt={organisation.attributes.name}
            style={{
                maxWidth: maxWidth,
                maxHeight: maxHeight,
                ...style,
            }}
        />
    );
};

const mapStateToProps = (state, props) => {
    return {
        logo: getRelationship(state, 'logo', props.organisation),
    }
}

export default connect(mapStateToProps)(OrganisationLogo);
