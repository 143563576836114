import axios from 'axios';
import { getCurrentToken, hasToken, isCurrentTokenExpired, refreshToken } from '../helpers/jwt';
import { errorHandler, extractErrorMessage } from '../api';
import { globalBackendApi } from '../App';

export function getBaseHost() {
    switch (window.location.hostname) {
        case 'localhost':
            return 'http://industry.local';

        // CI is different to local.
        case '127.0.0.1':
        case 'frontend':
            return 'http://backend';

        default:
            return 'https://' + window.location.hostname;
    }
}

export function getBaseUrl() {
    switch (window.location.hostname) {
        case 'localhost':
            return 'http://industry.local';

        // CI is different to local.
        case '127.0.0.1':
        case 'frontend':
            return 'http://backend';

        default:
            return 'https://' + window.location.hostname + '/backend';
    }
}

/**
 * @return AxiosInstance
 */
let jsonApiClients = {};
export function jsonApi() {
    // We must cache the instances by the access token so that if a user logs out and then in
    // with another account they get a newly authenticated http client.
    let jwt = getCurrentToken();
    if (!jsonApiClients[jwt]) {
        jsonApiClients[jwt] = http({
            'Content-Type': 'application/vnd.api+json',
            'Accept': 'application/vnd.api+json',
        })
    }
    return jsonApiClients[jwt];
}

export function refreshAccessTokenInterceptor(config) {
    if (hasToken() && isCurrentTokenExpired()) {
        return refreshToken()
            .then(token => {
                config.headers.Authorization = 'Bearer ' + token;
                return config;
            })
            .catch(error => {
                console.log('failed refreshing token', error);
                return config;
            });
    }

    return Promise.resolve(config);
}

let version = '';
let versionMismatchDetected = false;
function detectVersionMismatchInterceptor(response) {
    if (typeof response.headers !== 'undefined' && typeof response.headers['x-unearthed-version'] !== 'undefined') {
        if (version === '') {
            version = response.headers['x-unearthed-version'];
            return response;
        }

        if (version !== response.headers['x-unearthed-version']) {
            versionMismatchDetected = true;
        }
    }

    return response;
}

export function hasVersionMismatch() {
    return versionMismatchDetected;
}

export function http(headers = {}) {
    let baseHeaders = {};
    let jwt = getCurrentToken();
    if (jwt) {
        baseHeaders.Authorization = 'Bearer ' + jwt;
    }

    let instance = axios.create({
        baseURL: getBaseUrl(),
        headers: {
            ...baseHeaders,
            ...headers,
        },
    });

    instance.interceptors.response.use(detectVersionMismatchInterceptor)

    const clearTokenInterceptor = instance.interceptors.request.use(refreshAccessTokenInterceptor);
    instance.clearRefreshTokenInterceptor = () => {
        instance.interceptors.request.eject(clearTokenInterceptor);
        return instance;
    }

    return instance;
}

export function makeGetApiCall(dispatch, key, url) {
    return makeApiCall(dispatch, key, url, {
        method: 'get',
        url: url,
    })
}

export function withToken(callback) {
    return jsonApi().get('/session/token')
        .then(tokenResponse => callback(tokenResponse.data))
        .catch(errorHandler);
}

export function makeDeleteApiCall(dispatch, key, url) {
    return withToken(token => {
        return jsonApi().delete(url, {
            headers: {
                'X-CSRF-Token': token,
            }
        })
            .then(response => {
                return dispatch({
                    type: 'API_SUCCESS',
                    key: key,
                    payload: response.data,
                })
            })
            .catch(error => {
                console.log(error, error.response);
                return dispatch({
                    type: 'API_FAILURE',
                    key: key,
                    payload: error.message,
                })
            });
    })
}

export function makePatchApiCall(dispatch, key, url, data) {
    // Temporary workaround until we remove these API calls in favour
    // of hooks. Not always available in tests.
    if (globalBackendApi && typeof globalBackendApi.clearCache === 'function') {
        globalBackendApi.clearCache();
    }

    return withToken(token => {
        return makeApiCall(dispatch, key, {
            method: 'patch',
            url: url,
            data: JSON.stringify(data),
            headers: {
                'X-CSRF-Token': token,
            }
        })
    })
}

export function makePostApiCall(dispatch, key, url, data) {
    // Temporary workaround until we remove these API calls in favour
    // of hooks. Not always available in tests.
    if (globalBackendApi && typeof globalBackendApi.clearCache === 'function') {
        globalBackendApi.clearCache();
    }

    return withToken(token => {
        return makeApiCall(dispatch, key, {
            method: 'post',
            url: url,
            data: JSON.stringify(data),
            headers: {
                'X-CSRF-Token': token,
            }
        })
    })
}

export function post(client, url, data = {}) {
    return withToken(token => {
        return client({
            method: 'post',
            url: url,
            data: JSON.stringify(data),
            headers: {
                'X-CSRF-Token': token,
            }
        })
    })
}

export function makeApiCall(dispatch, key, options) {
    dispatch({
        type: 'API_REQUEST',
        key: key
    })

    return jsonApi()(options)
        .then(response => {
            return dispatch({
                type: 'API_SUCCESS',
                key: key,
                payload: response.data,
            })
        })
        .catch(error => {
            console.log(options, error, error.response, error.message, error.status);
            return dispatch({
                type: 'API_FAILURE',
                key: key,
                payload: extractErrorMessage(error),
                response: error.response,
            })
        });
}
