// @flow

import React from 'react';
import { Tabs } from 'antd';
import SignupActivity from './SignupActivity';
import SubmissionActivity from './SubmissionActivity';
import ForumActivity from './ForumActivity';
import useJsonApiGet from '../../api/useJsonApiGet';
import ActivityFeed from './ActivityFeed';
import styled from 'styled-components';

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
    @media (max-width: 400px) {
        .ant-tabs-nav-wrap {
            width: 220px;
        }
    }
`

type Props = {
    remoteCompetitionId: string,
}

const ActivityTabs = ({ remoteCompetitionId }: Props) => {
    const { response, jsonapi } = useJsonApiGet(`/api/unearthed/activity-stream/${remoteCompetitionId}`)
    let activities = response.data.data || [];

    return (
        <StyledTabs
            tabPosition="top"
            defaultActiveKey="all"
        >
            <TabPane tab="All" key="all">
                <ActivityFeed
                    items={activities.slice(0, 12).map(activity => {
                        switch (activity.type) {
                            case 'competition_participation--competition_participation':
                                return SignupActivity.render(activity, jsonapi);
                            case 'submission--data_science':
                            case 'submission--hackathon':
                                return SubmissionActivity.render(activity, jsonapi);
                            case 'forum_question--forum_question':
                                return ForumActivity.render(activity, jsonapi);
                            default:
                                return null;
                        }
                    }).filter(a => a !== null)}
                />
            </TabPane>

            <TabPane tab="Sign ups" key="participants">
                <SignupActivity
                    activities={activities.filter(a => a.type === 'competition_participation--competition_participation')}
                    jsonapi={jsonapi}
                />
            </TabPane>

            <TabPane tab="Submissions" key="submissions">
                <SubmissionActivity
                    activities={activities.filter(a => ['submission--data_science', 'submission--hackathon'].includes(a.type))}
                    jsonapi={jsonapi}
                />
            </TabPane>

            <TabPane tab="Forum Posts" key="forum">
                <ForumActivity
                    activities={activities.filter(a => a.type === 'forum_question--forum_question')}
                    jsonapi={jsonapi}
                />
            </TabPane>
        </StyledTabs>
    );
};

export default ActivityTabs;
