// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Row, Col, Spin, Radio, Popconfirm, Divider } from 'antd';
import { Input, Select, message } from '../components/Ant';
import { TaskType } from '../types';
import { updateTask, addTaskToTaskTemplate, addNewTaskToTaskWorkflow, deleteTask } from '../actions';
import { mergeTaskData, mergeEntityAttributes } from '../helpers';
import FormActions from '../components/Form/FormActions';
import { extractErrorMessage } from '../api';
import Task from '../components/Task/Task';

type Props = {
    form: Object,
    task: TaskType,
    updateTask: Function,
    addTaskToParent: Function,
    doDeleteTask: Function,
    parent: Object,
    afterSave?: Function,
}

type State = {
    selectedTaskType: string,
    isLoading: boolean,
    updatedTask: TaskType,
}

class EditTaskForm extends Component<Props, State> {

    static defaultProps = {
        task: {
            type: 'task--task',
            attributes: {
                title: '',
                data: { type: '', is_required: '0' },
                help: '',
                parent_type: 'task_template--task_template',
                comments: {
                    status: 2,
                },
            }
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedTaskType: '',
            isLoading: false,
            updatedTask: props.task,
        }
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onChangeType = this.onChangeType.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleError = this.handleError.bind(this);
        this.renderTaskDefaultValueForm = this.renderTaskDefaultValueForm.bind(this);
        this.buildUpdatedTask = this.buildUpdatedTask.bind(this);
    }

    onChangeType: Function
    onChangeType(e) {
        this.setState({ selectedTaskType: e }, () => {
            this.buildUpdatedTask();
        })
    }

    handleSuccess: Function
    handleSuccess(result) {
        message.success('Task updated');
        this.setState({ isLoading: false });
        if (this.props.afterSave) {
            this.props.afterSave();
        }
    }

    handleError: Function
    handleError(error) {
        message.warning(extractErrorMessage(error));
        this.setState({ isLoading: false });
        console.log(error);
    }

    onSave: Function
    onSave(e) {
        e.preventDefault();

        this.props.form.validateFields()
            .then(result => {
                let updatedTask = this.state.updatedTask;
                this.setState({ isLoading: true });
                if (updatedTask.id) {
                    this.props.updateTask(updatedTask)
                        .then(this.handleSuccess)
                        .catch(this.handleError)
                }
                else {
                    this.props.addTaskToParent(this.props.parent, updatedTask)
                        .then(this.handleSuccess)
                        .catch(this.handleError)
                }
            })
            .catch(error => message.warning('Please complete all fields'))
    }

    buildUpdatedTask: Function
    buildUpdatedTask() {
        return this.props.form.validateFields()
            .then(result => {

                let updatedTask = mergeTaskData(this.state.updatedTask, {
                    type: result.task_type,
                    is_required: result.task_required,
                });
                updatedTask = mergeEntityAttributes(updatedTask, {
                    title: result.task_title,
                    help: result.task_help,
                });

                if (typeof updatedTask.attributes.data.value === 'undefined') {
                    // Checkboxes is the only one to store values as an array.
                    updatedTask.attributes.data.value = result.type === 'checkboxes' ? [] : '';
                }

                // If this task type supports options, split them into an array.
                if (result.task_options) {
                    updatedTask.attributes.data.options = result.task_options.split(',').map(item => item.trim());
                }

                return this.setState({ updatedTask })
                // Ignore all validation errors while building the tasks, they are handled on submit.
            }).catch(e => e);
    }

    onDelete: Function
    onDelete(task) {
        this.props.doDeleteTask(task, this.props.parent.id)
            .then(response => message.success('Task deleted'))
            .catch(error => message.error('Failed to delete task. ' + extractErrorMessage(error)))
    }

    renderTaskDefaultValueForm: Function
    renderTaskDefaultValueForm() {
        return <>
            <h5>Task Preview</h5>
            <p>Enter prepopulated values here.</p>
            <Task
                isPreview={false}
                isFullView={false}
                task={this.state.updatedTask}
                onChange={defaultTask => this.setState({ updatedTask: defaultTask })}
                noSubmit={true}
            />
        </>
    }

    render() {
        let { form, task } = this.props;

        return (
            <Spin spinning={this.state.isLoading}>
                <Form
                    onSubmit={this.onSave}
                    onChange={this.buildUpdatedTask}
                    className="edit-task-form"
                >

                    <Row gutter={8}>
                        <Col span={18}>
                            <Form.Item label="Title">
                                {form.getFieldDecorator('task_title', {
                                    rules: [{ required: true, message: 'Please input the task title.' }],
                                    initialValue: task.attributes.title,
                                })(
                                    <Input name="task_title" />
                                )}
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="Task Type">
                                {form.getFieldDecorator('task_type', {
                                    rules: [{ required: true, message: 'Please select the task type.' }],
                                    initialValue: task.attributes.data.type,
                                })(
                                    <Select onChange={this.onChangeType} className="edit-task-type-select">
                                        <Select.Option value="checkboxes" name="checkboxes">Checkboxes</Select.Option>
                                        <Select.Option value="file" name="file">File</Select.Option>
                                        <Select.Option value="money" name="money">Money</Select.Option>
                                        <Select.Option value="number" name="number">Number</Select.Option>
                                        <Select.Option value="selectbox" name="selectbox">Select Box</Select.Option>
                                        <Select.Option value="text" name="text">Text Area</Select.Option>
                                        <Select.Option value="textfield" name="textfield">Text Field</Select.Option>
                                        <Select.Option value="textfield_multi" name="textfield_multi">Text Field Multiple</Select.Option>
                                        <Select.Option value="user_selection" name="user_selection">User Selection</Select.Option>
                                        <Select.Option value="user_selection_multi" name="user_selection_multi">User Selection Multiple</Select.Option>
                                        <Select.Option value="timeline" name="timeline">Timeline</Select.Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item label="Help" help={"This appears as a tooltip on the task"}>
                        {form.getFieldDecorator('task_help', {
                            initialValue: task.attributes.help,
                        })(
                            <Input name="task_help" />
                        )}
                    </Form.Item>

                    {['checkboxes', 'selectbox'].includes(this.state.selectedTaskType || task.attributes.data.type) ?
                        <Form.Item label="Options" help="Comma separated list of one or more options.">
                            {form.getFieldDecorator('task_options', {
                                rules: [{ required: true, message: 'Please input the options to choose/select from.' }],
                                initialValue: Array.isArray(task.attributes.data.options) ? task.attributes.data.options.join(', ') : '',
                            })(
                                <Input name="task_options" />
                            )}
                        </Form.Item>
                        : null
                    }

                    <Form.Item label="Is this task always required?">
                        {form.getFieldDecorator('task_required', {
                            initialValue: task.attributes.data.is_required,
                        })(
                            <Radio.Group>
                                <Radio.Button value="1">Yes</Radio.Button>
                                <Radio.Button value="0">No</Radio.Button>
                            </Radio.Group>
                        )}
                    </Form.Item>

                    <Divider />
                    {this.renderTaskDefaultValueForm()}

                    <FormActions>
                        {this.props.task.id ?
                            <Popconfirm
                                title={"Are you sure you want to delete this task?"}
                                onConfirm={() => this.onDelete(task)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="ghost" style={{ marginLeft: '8px' }}  className="edit-task-delete">Delete</Button>
                            </Popconfirm> : null}

                        <Button type="primary" htmlType="submit" className="edit-task-submit">Save Task</Button>
                    </FormActions>
                </Form>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateTask: (task, parentId) => dispatch(updateTask(task, parentId)),
        doDeleteTask: (task, parentId) => {
            return dispatch(deleteTask(task, parentId))
        },
        addTaskToParent: (parent, task) => dispatch(
            task.attributes.parent_type === 'task_template--task_template' ?
                addTaskToTaskTemplate(parent, task) :
                addNewTaskToTaskWorkflow(parent, task)
        )
            .then(result => {
                if (result.type === 'API_SUCCESS') {
                    return Promise.resolve(result);
                }
                return Promise.reject(result);
            }),
    }
}

const ConnectedEditTaskForm = connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'edit_task' })(EditTaskForm));
ConnectedEditTaskForm.displayName = 'EditTaskForm';
export default ConnectedEditTaskForm;
