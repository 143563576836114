import React from 'react';
import styled from 'styled-components';
import CreateNewChallenge from '../Modals/CreateNewChallenge';
import { Icon, Button } from 'antd';

const Wrapper = styled.div`
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 110px;
        width: 248px;
        margin-bottom: 8px;
        font-weight: 500;
    } 
`

const KanbanNewOpportunity = () => {
    return (
        <CreateNewChallenge>
            {showModal => 
                <Wrapper>
                    <Button onClick={showModal} className="kanban-new-opportunity">
                        New Challenge
                        <Icon style={{ fontSize: '16px' }} className="primary-color" type="plus" />
                    </Button>
                </Wrapper>
            }
        </CreateNewChallenge>
    );
};

export default KanbanNewOpportunity;
