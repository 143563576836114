// @flow

import * as React from 'react';
import styled from 'styled-components';

const tileDimensions = {
    default: {
        width: '164px',
        height: '230px'
    },
    large: {
        width: '229px',
        height: '319px'
    }
}

const tileimageSize = {
    default: {
        height: '110px',
    },
    large: {
        height: '160px',
    }
}

const tileTitleSize = {
    default: {
        fontSize: '14px',
    },
    large: {
        fontSize: '16px',
    }
}

const TileWrapper = styled.div`
    padding: 8px;
    background: #FFF;
    width: ${props => tileDimensions[props.cssSize].width};
    height: ${props => tileDimensions[props.cssSize].height};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    word-break: break-word;
    border: 1px solid #DDD;

    @media (max-width: 1025px) {
        width: 132px;
        height: 184px;
    }
`

const HeroImageWrapper = styled.div`
    border-radius: 10px;
    height: ${props => tileimageSize[props.cssSize].height};
    overflow: hidden;
    background: #F5F5F5;
    flex-shrink: 0;
    position: relative;

    @media (max-width: 1025px) {
        height: 80px;
    }

    img {
        height: 100%;
        width: 100%;
        border: 1px solid #EEE;
        object-fit: cover;

        @media (max-width: 1025px) {
            height: 80px;
        }
    }

`

const ContentWrapper = styled.div`
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;

    @media (max-width: 1025px) {
        padding: 8px 0 0 0;
    }
`

const CardTitle = styled.h3`
    font-size: ${props => tileTitleSize[props.cssSize].fontSize};
    margin: 0;

    @media(max-width: 1024px) {
        font-size: 12px;
    }
`

type Props = {
    image: React.Node,
    title: React.Node,
    children?: React.Node,
    above_content: React.Node,
}

const Tile = ({ image, title, children, above_content, size = 'default' }: Props) => {
    return (
        <TileWrapper cssSize={size}>
            <HeroImageWrapper cssSize={size}>
                {image}
            </HeroImageWrapper>

            {above_content}

            <ContentWrapper>
                <CardTitle cssSize={size}>
                    {title}
                </CardTitle>

                <div style={{ marginTop: 'auto' }}>
                    {children}
                </div>
            </ContentWrapper>

        </TileWrapper>
    );
};

export default Tile;
