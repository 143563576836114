// @flow

import React, { useCallback, useState } from 'react';
import { Form, Button, message } from 'antd';
import { Input } from '../components/Ant';
import FormActions from '../components/Form/FormActions';
import UploadDropArea from '../components/UploadDropArea';
import { extractErrorMessage } from '../api';
import { mergeEntityAttributes } from '../helpers';
import OrganisationLogo from '../components/Organisation/OrganisationLogo';
import { createOrganisation, updateOrganisation } from '../api/organisations';
import useFileUpload from '../api/useFileUpload';

type Props = {
    organisation: Object,
    logo: Object,
    form: Object,
    onAfterSave?: Function,
    onAfterUpload?: Function
}

const EditOrganisationForm = ({ organisation, logo = null, onAfterUpload, onAfterSave, form }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { isUploadInProgress, handleUpload, uploadProgress } = useFileUpload(`/jsonapi/organisation/organisation/${organisation.id}/logo`)

    const errorHandler = useCallback(error => {
        console.log(error);
        message.error(extractErrorMessage(error));
    }, []);

    const successHandler = useCallback((text, result) => {
        setIsLoading(false);
        message.success(text);

        if (typeof onAfterSave === 'function') {
            onAfterSave(result.data.data);
        }
    }, [onAfterSave])

    const onSave = useCallback(e => {
        e.preventDefault();

        form.validateFields()
            .then(result => {
                setIsLoading(true);

                if (organisation.id) {
                    let updatedOrganisation = mergeEntityAttributes(organisation, {
                        name: result.name,
                    });

                    updateOrganisation(updatedOrganisation)
                        .then(response => successHandler('Organisation updated', response))
                        .catch(errorHandler);
                }
                else {
                    createOrganisation(result.name)
                        .then(response => successHandler('Organisation created', response))
                        .catch(errorHandler);
                }
            })

    }, [organisation, form, errorHandler, successHandler]);

    const doHandleUpload = useCallback(files => {
        handleUpload(files)
            .then(result => {
                if (typeof onAfterUpload === 'function') {
                    onAfterUpload(result);
                }
            })
    }, [onAfterUpload, handleUpload]);

    return (
        <Form onSubmit={onSave}>
            <Form.Item label="Name">
                {form.getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Please input the organisation name.' }],
                    initialValue: organisation.attributes.name,
                })(
                    <Input name="label" />
                )}
            </Form.Item>

            {organisation.id ?
                <Form.Item label="Logo">
                    <OrganisationLogo organisation={organisation} />
                    <br />
                    <br />
                    <UploadDropArea
                        handleUpload={doHandleUpload}
                        uploadInProgress={isUploadInProgress}
                        uploadProgress={uploadProgress}
                        uploadInstructions={"Supports single image upload. PNG or JPG supported under 2MB."}
                        options={{
                            multiple: false,
                        }}
                    />
                </Form.Item>
                : null}

            <FormActions>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                >Save</Button>
            </FormActions>
        </Form>
    );
};

export default Form.create({ name: 'edit_organisation' })(EditOrganisationForm);
export { EditOrganisationForm as _EditOrganisationForm }
