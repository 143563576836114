// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Avatar as AntdAvatar, Tooltip } from 'antd';
import { stringToColor, nameToInitials } from '../helpers';
import { getRelationship } from '../selectors';
import { User } from '../types';

type Props = {
    user: User,
    image?: Object,
    style?: Object,
    size?: string,
    color?: string,
    tooltip?: boolean,
    nameSuffix?: string,
    placement?: string,
}

const Avatar = ({ user, size = 'small', color, tooltip = true, nameSuffix = '', placement = 'top', image, style = {}, ...props }: Props) => {
    if (!user) {
        return null;
    }

    if (image && image.links.thumbnail_cropped) {
        return (
            <Tooltip placement={placement} title={tooltip ? user.attributes.display_name + nameSuffix : ''}>
                <AntdAvatar
                    size={size}
                    src={image.links.thumbnail_cropped.href}
                    style={style}
                    {...props}
                />
            </Tooltip>
        );
    }

    return (
        <Tooltip placement={placement} title={tooltip ? user.attributes.display_name + nameSuffix : ''}>
            <AntdAvatar
                style={{ flexShrink: 0, backgroundColor: color || stringToColor(user.attributes.display_name), ...style }}
                size={size}
                {...props}
            >
                {nameToInitials(user.attributes.display_name)}
            </AntdAvatar>
        </Tooltip>
    );
};


const mapStateToProps = (state, props) => {
    const { user } = props;
    return {
        image: getRelationship(state, 'field_picture', user),
    }
}

const mapDispatchToProps = dispatch => {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Avatar);
export { Avatar as _Avatar }
