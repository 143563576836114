// @flow

import * as React from 'react';
import styled from 'styled-components';

import Avatar from '../Avatar';
import { User } from '../../types';
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'

import dayjs from 'dayjs';
import { Icon } from 'antd';
dayjs.extend(advancedFormat)
dayjs.extend(relativeTime)

const ActivityItem = styled.div`
    padding: 16px 0;
    border-bottom: 1px solid ${props => props.theme.lightGrey};
    display: flex;
    align-items: flex-start;
    position: relative;
    &:last-child {
        border-bottom: none;
    }
`

const TopWrapper = styled.div`
    margin-bottom: 8px;

    p {
        margin: 0;
    }
`

const TopWrapperInner = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 360px) {
        .ant-avatar {
            display: none;
        }
    }
`

const Name = styled.span`
    font-weight: bold;
    color: ${props => props.theme.darkGrey};
`

const TimeAgo = styled.span`
    margin-left: auto
    padding-top: 7px;
`

const Content = styled.div`
    max-width: 100%;
    overflow-wrap: break-word;
`

const ActivityActions = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 11px;
    opacity: 0;

    ${ActivityItem}:hover & {
        opacity: 1;
    }
`

const TimeWrapper = styled.div`
    font-size: 12px;
    margin-bottom: 8px;
    color: ${props => props.theme.darkGrey};
`

type ActivtyItemType = {
    user: User,
    text: React.Node,
    timestamp: number,
    actions?: React.Node,
}

type Props = {
    items: Array<ActivtyItemType>,
    emptyText?: string,
}

const ActivityFeed = ({ items, emptyText = 'No activities' }: Props) => {
    return (
        <div>
            {items.length === 0 && emptyText.length ? <p>{emptyText}</p> : null}
            {items.map((item, i) => (
                <ActivityItem key={i} className="activity-item">
                    <Content>
                        {item.timestamp ?
                            <TimeWrapper>
                                <Icon style={{ marginRight: '8px' }} type="clock-circle" />
                                <TimeAgo>{dayjs.unix(item.timestamp).fromNow()}</TimeAgo>
                            </TimeWrapper>
                            : null
                        }
                        <TopWrapper>
                            <TopWrapperInner>
                                <Avatar style={{ marginRight: '16px', flexShrink: 0, }} size="default" user={item.user} />
                                <p>{item.user ? <Name>{item.user.attributes.field_full_name || item.user.attributes.display_name} </Name> : null}{item.text}</p>
                            </TopWrapperInner>
                        </TopWrapper>
                    </Content>
                    {item.actions ? <ActivityActions className="activity-actions">{item.actions}</ActivityActions> : null}
                </ActivityItem>
            ))}
        </div>
    );
};

export default ActivityFeed;
