// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Spin } from 'antd';
import { useSetPageTitle } from '../hoc/setPageTitle';
import { Box, GenericError, Spacer } from '../components/Common';
import TwoCol from '../components/Common/TwoCol';
import UserCard from '../components/Common/UserCard';
import ArrowLink from '../components/Common/ArrowLink';
import CompetitionLeaderboard from '../components/Competition/CompetitionLeaderboard';
import ParticipantMap from '../components/Charts/ParticipantMap';
import SubmissionList from '../components/Competition/SubmissionList';
import useCompetitionMetadata from '../components/Competition/useCompetitionMetadata';
import FullWidthContentBlock from '../components/FullWidthContentBlock';
import CompetitionStatsBlock from '../components/Competition/CompetitionStatsBlock';
import ActivityTabs from '../components/Activity/ActivityTabs';
import PlaybookNav from '../components/Playbook/PlaybookNav';
import { get } from '../helpers'
import PlaybookUpdateFeed from '../components/Playbook/PlaybookUpdateFeed';
import PlaybookOverview from '../components/Competition/PlaybookOverview';
import usePlaybook from '../api/usePlaybook';
import PlaybookTitle from '../components/Playbook/PlaybookTitle';
import PhaseActions from '../components/Phase/PhaseActions';
import CompetitionRunTabs from '../components/Competition/CompetitionRunTabs';
import AlignRight from '../components/Common/AlignRight';

const CompetitionRun = ({ match }) => {
    const playbookId = match.params.id;
    const { collaborators, isLoading, isFailed, failedErrorMessage, workflows } = usePlaybook(playbookId);
    const competition = workflows.find(w => w.type === 'task_workflow--competition');

    const workflowStatus = get(competition, 'attributes.workflow_status');
    const competitionRemoteId = get(competition, 'attributes.remote_uuid', null);
    const [isLoadingMetadata, metadata] = useCompetitionMetadata(competitionRemoteId);
    useSetPageTitle(isLoading ? 'Loading...' : 'Competition Run');

    if (isLoading || isFailed) {
        return (
            <div>
                <FullWidthContentBlock>
                    <br />
                    <br />
                    <br />
                    {isLoading ? <Spin spinning={true}></Spin> : <GenericError
                        title={failedErrorMessage}
                        message="This incident has been reported and will be investigated. If it persists please let us know."
                    />}
                </FullWidthContentBlock>
            </div>
        );
    }

    return (
        <div>
            {get(competition, 'attributes.playbook_id') ?
                <>
                    <PlaybookNav
                        playbookId={playbookId}
                        title={<PlaybookTitle playbookId={playbookId} edit />}
                        mode={competition.attributes.workflow_status === 'complete' ? 'results' : 'live'}
                        actions={
                            <PhaseActions
                                playbookId={playbookId}
                                workflow={competition}
                                phase={competition.attributes.workflow_status === 'complete' ? 'results' : 'live'}
                            />
                        }
                        tabs={<CompetitionRunTabs
                            competition={competition}
                            activeTab={"overview"}
                            playbookId={playbookId}
                            workflowStatus={competition.attributes.workflow_status}
                        />}
                    />
                    <FullWidthContentBlock>
                        <PlaybookOverview
                            playbookId={get(competition, 'attributes.playbook_id')}
                        />
                    </FullWidthContentBlock>
                </>
                : null}

            {workflowStatus === 'complete' || workflowStatus === 'published' ? null :
                <FullWidthContentBlock>
                    <Spacer>
                        <Box>
                            {isLoadingMetadata ? null : <p>This competition has not been published on Unearthed yet.</p>}
                        </Box>
                    </Spacer>
                </FullWidthContentBlock>
            }

            <FullWidthContentBlock>
                <TwoCol>
                    <div>
                        <Spacer>
                            <PlaybookUpdateFeed
                                playbookId={competition.attributes.playbook_id}
                                workflowId={competition.id}
                                title={<h2>Timeline</h2>}
                            />
                        </Spacer>

                        {workflowStatus === 'complete' || workflowStatus === 'published' ?
                            <Spacer>
                                <Box>
                                    <ActivityTabs remoteCompetitionId={competitionRemoteId} />
                                </Box>
                            </Spacer>
                            : null}
                    </div>

                    <div>
                        {(workflowStatus === 'complete' || workflowStatus === 'published') && metadata.submissionTypeId === 'data_science' ?
                            <Spacer>
                                <Box>
                                    <h2>Leaderboard</h2>
                                    <CompetitionLeaderboard competition={competition} />
                                </Box>
                            </Spacer>
                            : null
                        }

                        {workflowStatus === 'complete' || workflowStatus === 'published' ?
                            <>
                                <Spacer>
                                    <Box>
                                        <h2>Stats</h2>
                                        <CompetitionStatsBlock competition={competition} />
                                    </Box>
                                </Spacer>

                                <Spacer>
                                    <Box>
                                        <h2>Submissions</h2>
                                        <SubmissionList competition={competition} limit={3} />
                                        <AlignRight>
                                            <ArrowLink url={`/competition/${competition.id}/submissions`}>
                                                see all submissions
                                            </ArrowLink>
                                        </AlignRight>
                                    </Box>
                                </Spacer>
                            </>
                            : null}

                        <Spacer>
                            <Box>
                                <h2>Key Stakeholders</h2>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    {collaborators.slice(0, 3).map(user => <UserCard
                                        key={user.id}
                                        name={user.attributes.display_name}
                                        details={user.attributes.field_position}
                                    />)}
                                </div>

                            </Box>
                        </Spacer>

                        {workflowStatus === 'complete' || workflowStatus === 'published' ?
                            <Spacer>
                                <Box>
                                    <h2>Innovators</h2>
                                    <ParticipantMap competition={competition} />
                                </Box>
                            </Spacer>
                            : null
                        }

                    </div>
                </TwoCol>

            </FullWidthContentBlock>
        </div>
    );
};

export default withRouter(CompetitionRun);
