import React from 'react';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import { Icon } from 'antd';
import useTaskWorkflow from '../../api/useTaskWorkflow';
import { get } from '../../helpers';

const StyledLink = styled.div`
    font-weight: bold;
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    width: 66.66666667%;

    @media (max-width: 1599px) {
      width: calc(62.5% - 8px);
    }

    @media (max-width: 1199px) {
      width: 100%;
    }

    @media (max-width: 340px) {
      font-size: 12px;
    }
`

type Props = {
   remoteTaskWorkflowUuid: string,
}

const SubmissionNavLinks = ({ submission, remoteTaskWorkflowUuid, history }: Props) => {
   const { taskWorkflow } = useTaskWorkflow('competition', remoteTaskWorkflowUuid, null, true);
   const nextLink = get(submission, 'links.next.meta.linkParams.uuid', '');
   const previousLink = get(submission, 'links.previous.meta.linkParams.uuid', '');
   const delta = get(submission, 'links.previous.meta.linkParams.delta', '') || get(submission, 'links.next.meta.linkParams.delta', '');
   const count = get(submission, 'links.previous.meta.linkParams.submission_count', 0) || get(submission, 'links.next.meta.linkParams.submission_count', 0);

   return (
      <StyledLink>

         {previousLink.length ?
            <Link
               to={previousLink}
            >
               <Icon type="caret-left" />
               Previous
            </Link>
            : <span></span>}

         <Link
            to={taskWorkflow ? `/competition/${taskWorkflow.id}/submissions` : ''}
         >
            {delta} of {count} Submissions
         </Link>

         {nextLink.length ?
            <Link
               to={nextLink}
            >
               Next
                  <Icon type="caret-right" />
            </Link>
            : <span></span>}

      </StyledLink>
   )
}

export default withRouter(SubmissionNavLinks);