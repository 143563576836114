import React from 'react';
import styled from 'styled-components';

// We use these variables to add the margin in the UI onto the width of
// the container because the kanban itself using an overflow: scroll and we
// need the padding on the far right of the board which would be trimmed off without
// it being part of the column width.
let margin = 32;
let marginSmall = 16;

const ColumnWrapper = styled.div`
    // These props are duplicated on KanbanMenu.
    width: ${props => props.cssWidth ? (props.cssWidth + margin) + 'px' : (370 + margin) + 'px'};
    flex-shrink: 0;

    @media (max-width: 1025px) {
        width: ${props => props.cssWidth ? (177 + marginSmall) + 'px' : (288 + marginSmall) + 'px'};
    }

    &:last-child {
        margin-right: 0;
    }
`

const ColumnPanel = styled.div`
    background: #FFF;
    border-radius: 3px;
    padding: 16px;
    height: 100%;
    min-height: 100vh;
    width: ${props => props.cssWidth ? props.cssWidth + 'px' :  '370px'};

    @media (max-width: 1025px) {
        width: ${props => props.cssWidth ? '177px' : '288px'};
        padding: 8px;
    }
`

const Top = styled.div``
const BottomDoubleCol = styled.div`
    & > div {
        margin-bottom: 8px;
        float: left;
        margin-left: 8px;

        &:nth-child(odd) {
            margin-left: 0;
        }
    }
`
const BottomSingleCol = styled.div`
    & > div {
        margin-bottom: 8px;
    }
`


const KanbanColumn = ({ top, children, column }) => {
    const isSingle = !!column.single;
    return <ColumnWrapper
        single={isSingle}
        cssWidth={column.width}
        className={"kanban-column-" + column.id}
    >
        <ColumnPanel
            cssWidth={column.width}
        >
            <Top>
                {top}
            </Top>
            {isSingle ?
                <BottomSingleCol>
                    {children}
                </BottomSingleCol>
                :
                <BottomDoubleCol>
                    {children}
                </BottomDoubleCol>
            }
        </ColumnPanel>
    </ColumnWrapper>
}

export default KanbanColumn;
