// @flow

import React, { useCallback, useState } from 'react';
import { Form, Button, message, Spin, Checkbox } from 'antd';
import { Input } from '../components/Ant';
import FormActions from '../components/Form/FormActions';
import { extractErrorMessage } from '../api';
import { mergeEntityAttributes } from '../helpers';
import { createCategory, updateCategory, deleteCategory } from '../api/tags';
import useJsonApiGet from '../api/useJsonApiGet';

type Props = {
    form: Object,
    category: Object,
    onAfterSave?: Function,
}

const EditTagCategoryForm = ({ category, form, onAfterSave }: Props) => {
    const { response } = useJsonApiGet('/jsonapi/task_workflow_type/task_workflow_type');
    const taskWorkflowTypes = response.data.data || [];

    const [isLoading, setIsLoading] = useState(false);

    const errorHandler = useCallback(error => {
        console.log(error);
        setIsLoading(false);
        message.error(extractErrorMessage(error));
    }, []);

    const successHandler = useCallback((text, result) => {
        setIsLoading(false);
        message.success(text);

        if (typeof onAfterSave === 'function') {
            onAfterSave(result.data.data);
        }
    }, [onAfterSave])

    const onSave = useCallback(e => {
        e.preventDefault();

        form.validateFields()
            .then(result => {
                setIsLoading(true);
                const newAttributes = {
                    name: result.name,
                    enabled_workflow_types: result.enabled_workflow_types || [],
                }
                if (category.id) {
                    let updated = mergeEntityAttributes(category, newAttributes);

                    updateCategory(updated)
                        .then(response => successHandler('Category updated', response))
                        .catch(errorHandler);
                }
                else {
                    createCategory(newAttributes.name, newAttributes.enabled_workflow_types)
                        .then(response => {
                            form.resetFields();

                            // We have to wait a second becase Drupal takes a while to register the new route.
                            setTimeout(() => {
                                successHandler('Category created', response)
                            }, 2000);
                        })
                        .catch(errorHandler);
                }
            })

    }, [category, form, errorHandler, successHandler]);

    const handleDelete = useCallback(() => {
        setIsLoading(true);
        deleteCategory(category)
            .then(response => {
                if (typeof onAfterSave === 'function') {
                    onAfterSave(response.data.data);
                }
            })
            .catch(errorHandler);
    }, [category, errorHandler, onAfterSave])

    return (
        <Spin spinning={isLoading}>
            <Form onSubmit={onSave}>
                <Form.Item label="Name">
                    {form.getFieldDecorator('name', {
                        rules: [{ required: true, message: 'Please input the category name.' }],
                        initialValue: category.attributes.name,
                    })(
                        <Input name="label" autoFocus />
                    )}
                </Form.Item>

                <Spin spinning={response.isLoading}>
                    <Form.Item label="Phase">
                        {form.getFieldDecorator('enabled_workflow_types', {
                            // Default to all selected.
                            initialValue: category.id ? category.attributes.enabled_workflow_types : taskWorkflowTypes.map(taskWorkflowType => taskWorkflowType.id),
                        })(
                            <Checkbox.Group>
                                {taskWorkflowTypes.map(taskWorkflowType => <Checkbox key={taskWorkflowType.id} value={taskWorkflowType.id}>{taskWorkflowType.attributes.label}</Checkbox>)}
                            </Checkbox.Group>
                        )}
                    </Form.Item>
                </Spin>

                <FormActions>
                    <Button onClick={handleDelete}>Delete</Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >Save</Button>
                </FormActions>
            </Form>
        </Spin>
    );
};

export default Form.create({ name: 'edit_tag_category' })(EditTagCategoryForm);
