// @flow

import React, { useCallback, useState } from 'react';
import { Form, Spin } from 'antd';
import { Input, Button, message } from '../components/Ant';
import UploadDropArea from '../components/UploadDropArea';
import { TaskWorkflow } from '../types';
import { mergeEntityAttributes } from '../helpers';
import RemovableImage from '../components/RemovableImage';
import usePlaybook from '../api/usePlaybook';
import useFileUpload from '../api/useFileUpload';
import { withRouter } from 'react-router-dom';

type Props = {
    form: Object,
    competition: TaskWorkflow,
    update: Function,
    history: Function,
}

const PublishCompetitionForm = ({ competition, update, form, history }: Props) => {
    const [image, setImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { handleUpload, isUploadInProgress, uploadProgress } = useFileUpload(`/jsonapi/playbook/playbook/${competition.attributes.playbook_id}/image`)
    const { playbook, update: updatePlaybook } = usePlaybook(competition.attributes.playbook_id);
    let { getFieldDecorator, validateFields } = form;

    const onSave = useCallback(e => {
        e.preventDefault();
        validateFields()
            .then(result => {
                
                setIsLoading(true);
                update(mergeEntityAttributes(competition, {
                    title: result.title,
                    workflow_status: 'ready_to_publish',
                }))
                    // Once this succeeds, the LaunchCompetition component will remove this form from the DOM and
                    // render the "In Review with Unearthed" button.
                    .then(result => {
                        setIsLoading(false);
                        message.success('Your competition has been sent to Unearthed');
                        history.push(`/challenge/${competition.attributes.playbook_id}/live`);
                    })
                    .catch(error => {
                        message.warning(error);
                        setIsLoading(false);
                    });
            })
            .catch(errors => {
                console.log(errors);
                message.warning('Please complete all fields');
            })
    }, [competition, update, validateFields, history])

    const doHandleUpload = useCallback(files => {
        handleUpload(files)
            .then(result => {
                let response = result.pop();
                if (response) {
                    setImage(response.data.data);
                }
            })
    }, [handleUpload]);

    const removeImage = useCallback(() => {
        let updatedPlaybook = JSON.parse(JSON.stringify(playbook))
        updatedPlaybook.relationships.image.data = [];

        setIsLoading(true);
        updatePlaybook(updatedPlaybook)
            .then(response => {
                setImage(null);
                setIsLoading(false);
            });
    }, [playbook, updatePlaybook])

    return (
        <Spin spinning={isLoading}>
            <Form onSubmit={onSave}>
                <Form.Item label="Competition Title">
                    {getFieldDecorator('title', {
                        rules: [{ required: true, message: 'Please input the competition title.' }],
                        initialValue: competition.attributes.title,
                    })(
                        <Input name="title" />
                    )}
                </Form.Item>

                <Form.Item label="Hero Image">
                    <RemovableImage
                        handleRemove={removeImage}
                        style={{ textAlign: 'center', margin: '24px 0' }}
                        url={image ? image.links.api_image_large.href : null}
                        alt="Preview your competition hero"
                    />
                    <UploadDropArea
                        handleUpload={doHandleUpload}
                        uploadInProgress={isUploadInProgress}
                        uploadProgress={uploadProgress}
                        uploadInstructions={"Supports single image upload. PNG or JPG supported under 2MB."}
                        options={{ multiple: false }}
                    />
                </Form.Item>

                <div style={{ textAlign: 'right' }}>
                    <Button type="primary" htmlType="submit">Publish to Unearthed</Button>
                </div>
            </Form>
        </Spin>
    );
};

export default Form.create({ name: 'publish_competition' })(withRouter(PublishCompetitionForm));
export { PublishCompetitionForm as _PublishCompetitionForm }
