let localConfig = {};
try {
    localConfig = require('./local-config');
}
catch (e) {

}

const urlMap = {
    // Newcrest.
    'crowd.newcrest.com.au': {
        theme: 'newcrest',
        // gaTrackingId: 'UA-43016849-18',
        gaTrackingId: '',
        innovatorBaseHost: 'https://unearthed.solutions',
        sso_enabled: true,
        algoliaIndexName: 'prod_newcrest_challenge_platform'
    },
    'staging-5em2ouy-kvw7wxrqahxhg.au.platformsh.site': {
        theme: 'newcrest',
        gaTrackingId: '',
        innovatorBaseHost: 'https://development-q5nzhaa-lbjz5xns2dzm2.au.platformsh.site',
        sso_enabled: true,
        algoliaIndexName: 'dev_unearthed_challenge_platform'
    },

    // Unearthed.
    'staging-5em2ouy-ya7t2ul3y6774.au.platformsh.site': {
        theme: 'unearthed',
        gaTrackingId: '',
        innovatorBaseHost: 'https://development-q5nzhaa-lbjz5xns2dzm2.au.platformsh.site',
        algoliaIndexName: 'dev_unearthed_challenge_platform'
    },
    'build-challenge-7sypbcq-ya7t2ul3y6774.au.platformsh.site': {
        theme: 'unearthed',
        gaTrackingId: '',
        innovatorBaseHost: 'https://development-q5nzhaa-lbjz5xns2dzm2.au.platformsh.site',
        algoliaIndexName: 'dev_unearthed_challenge_platform'
    },
    'crowd.unearthed.solutions': {
        theme: 'unearthed',
        // gaTrackingId: 'UA-43016849-19',
        gaTrackingId: '',
        innovatorBaseHost: 'https://unearthed.solutions',
        algoliaIndexName: 'prod_unearthed_challenge_platform'
    },
    'localhost': {
        theme: 'unearthed',
        gaTrackingId: '',
        innovatorBaseHost: 'http://innovator.local',
        algoliaIndexName: 'local_unearthed_challenge_platform'
    },

    // Origin.
    'origin.unearthed.solutions': {
        theme: 'origin',
        gaTrackingId: '',
        innovatorBaseHost: 'https://unearthed.solutions',
        algoliaIndexName: 'prod_origin_challenge_platform'
    },

    // OZ Minerals.
    'ozminerals.unearthed.solutions': {
        theme: 'ozminerals',
        gaTrackingId: '',
        innovatorBaseHost: 'https://unearthed.solutions',
        algoliaIndexName: 'prod_ozminerals_challenge_platform'
    },
    'master-7rqtwti-h6g7gy4wnjl7s.au.platformsh.site': {
        theme: 'ozminerals',
        gaTrackingId: '',
        innovatorBaseHost: 'https://unearthed.solutions',
    },
}

const themeMap = {
    newcrest: {
        siteName: 'Newcrest Crowd',
        primaryColor: '#ac8b3b',
    },
    unearthed: {
        siteName: 'Unearthed Crowd',
        primaryColor: '#FF6600',
        multiOrganisations: true,
    },
    origin: {
        siteName: 'Origin Crowd',
        primaryColor: '#ec0001',
    },
    ozminerals: {
        siteName: 'Oz Minerals Crowd',
        primaryColor: '#faba00',
    }
}

function getActiveTheme() {
    let activeTheme = 'unearthed';

    if (typeof window !== 'undefined') {
        if (urlMap[window.location.hostname]) {
            activeTheme = urlMap[window.location.hostname].theme;
        }
    }
    else if (typeof global.process !== 'undefined' && process.env.PLATFORM_PROJECT) {
        // Map between platform project id's and the theme we want to use.
        activeTheme = {
            ya7t2ul3y6774: 'unearthed',
            kvw7wxrqahxhg: 'newcrest',
            pydzqbovpad6e: 'origin',
            h6g7gy4wnjl7s: 'ozminerals'
        }[process.env.PLATFORM_PROJECT]
    }
    return activeTheme;
}

function getTrackingCode() {
    if (typeof window === 'undefined' || typeof urlMap[window.location.hostname] === 'undefined') {
        return '';
    }
    return urlMap[window.location.hostname].gaTrackingId;
}

function getPrimaryColor() {
    return themeMap[getActiveTheme()].primaryColor;
}

function getSiteName() {
    return themeMap[getActiveTheme()].siteName;
}

function multiOrganisations() {
    return !!themeMap[getActiveTheme()].multiOrganisations;
}

function getInnovatorPortalBaseUrl() {
    return getInnovatorPortalBaseHost() + '/u';
}

function getSsoUrlIfEnabled() {
    if (isSsoEnabled()) {
        return window.location.origin + '/backend/saml_login?ReturnTo=' + window.location.origin + '/backend/client';
    }
    return '';
}

function isSsoEnabled() {
    if (typeof window === 'undefined' || typeof urlMap[window.location.hostname] === 'undefined') {
        return false;
    }
    return urlMap[window.location.hostname].sso_enabled === true;
}

function getInnovatorPortalBaseHost() {
    if (typeof window === 'undefined' || typeof urlMap[window.location.hostname] === 'undefined') {
        return 'http://innovator.local';
    }

    return urlMap[window.location.hostname].innovatorBaseHost;
}

function getAlgoliaIndexName() {
    if (typeof window === 'undefined' || typeof urlMap[window.location.hostname] === 'undefined') {
        return 'gitlab_unearthed_challenge_platform';
    }
    return urlMap[window.location.hostname].algoliaIndexName;
}

var config = null;
function getConfig() {
    if (config === null) {
        config = {
            activeTheme: getActiveTheme(),
            gaCode: getTrackingCode(),
            primaryColor: getPrimaryColor(),
            innovatorPortalBaseUrl: getInnovatorPortalBaseUrl(),
            innovatorPortalBaseHost: getInnovatorPortalBaseHost(),
            siteName: getSiteName(),
            sso_url: getSsoUrlIfEnabled(),
            sso_enabled: isSsoEnabled(),
            multiOrganisations: multiOrganisations(),
            algoliaIndexName: getAlgoliaIndexName(),
            themeMap: themeMap,
        };
        if (typeof localConfig === 'object') {
            Object.keys(localConfig).forEach(key => {
                config[key] = localConfig[key];
            })
        }
    }
    return config;
}

module.exports = getConfig;
