import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { Button, Modal } from '../Ant';
import TaskTemplateGrid from '../TaskTemplate/TaskTemplateGrid';
import { createCompetition as createCompetitionAction } from '../../actions';
import JsonApiRequestBuilder from '../../helpers/JsonApiRequestBuilder';
import { message } from '../Ant';
import ProgressWithTimer from '../ProgressWithTimer';
import { trackEvent } from '../../helpers/trackEvent';
import withPermissions from '../../hoc/withPermissions';
import { hasPermission } from '../../helpers/HasPermission';
import CompleteBadge from '../CompleteBadge';
import { searchClient } from '../Search/SearchInstance';
import useTaskTemplates from '../../api/useTaskTemplates';
import { createLinkFromTaskWorkflow } from '../TaskWorkflow/createLink';

const SolveChallengeModal = withRouter(({ children, afterComplete, createCompetition, history }) => {
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTaskTemplate, setSelectedTaskTemplate] = useState(null);
    const [challenge, setChallenge] = useState(null);
    const { taskTemplates, isLoading: isLoadingTemplates } = useTaskTemplates('competition');

    const handleOk = () => {
        if (!selectedTaskTemplate) {
            message.warning('Please select a competition type');
            return;
        }
        setIsLoading(true);
    }

    const shouldAutoCreate = useCallback(() => {
        return taskTemplates.length === 1;
    }, [taskTemplates])

    const showModal = useCallback((overrideChallenge) => {
        if (isLoadingTemplates) {
            return null;
        }

        if (overrideChallenge) {
            setChallenge(overrideChallenge);
        }

        if (shouldAutoCreate()) {
            setSelectedTaskTemplate(taskTemplates[0]);
        }
        setVisible(true);
    }, [isLoadingTemplates, setChallenge, setSelectedTaskTemplate, taskTemplates, shouldAutoCreate])

    const doCreateCompetition = () => {
        let request = new JsonApiRequestBuilder('task_workflow--competition')
            .attribute('title', ' ')
            .relationship('task_template', 'task_template--task_template', selectedTaskTemplate.id);

        trackEvent('competition', 'Created new', 'Solve Challenge Button');
        return createCompetition(request.build(), challenge.attributes.playbook_id);
    }

    return (
        <div>
            {typeof children === 'function' ?
                children(showModal) :
                <Button type="primary" onClick={showModal}>Solve Challenge</Button>
            }
            <Modal
                title={isLoading ? null : 'Solve with Unearthed'}
                titleIntro={isLoading ? null : 'The future of work'}
                visible={visible}
                onCancel={() => setVisible(false)}
                width={'100%'}
                className="solve-challenge"
                footer={[
                    shouldAutoCreate() || isLoading ? [] : [
                        <Button key="cancel" onClick={() => setVisible(false)}>Cancel</Button>,
                        <Button type="primary" key="create" onClick={handleOk}>Launch Competition</Button>,
                    ]
                ]}
            >
                {shouldAutoCreate() || isLoading ?
                    <ProgressWithTimer
                        hasStarted={true}
                        actionCallback={doCreateCompetition}
                        afterComplete={response => {
                            if (response.type === "API_SUCCESS") {
                                searchClient.clearCache();
                                history.push(createLinkFromTaskWorkflow(challenge, 'build'));

                                if (typeof afterComplete === 'function') {
                                    afterComplete();
                                }
                            }
                            else {
                                message.warning('Failed to create competition');
                            }
                        }}
                    >
                        progress
                    </ProgressWithTimer>
                    : <TaskTemplateGrid
                        onClick={setSelectedTaskTemplate}
                        taskWorkflowTypeId={'competition'}
                    />}
            </Modal>
        </div>
    )
})

const SolveChallenge = ({ challenge, createCompetition, permissions, children }) => {
    if (challenge.attributes.completed_tasks_count !== challenge.attributes.tasks_count && !hasPermission(permissions, 'forward incomplete task_workflow')) {
        return <CompleteBadge
            taskWorkflow={challenge}
            buttonText="Next Step"
            promptText="Complete remaining tasks to move forward"
        />
    }

    return <SolveChallengeModal createCompetition={createCompetition}>
        {showModal => <Button type="primary" onClick={() => showModal(challenge)}>Solve Challenge</Button>}
    </SolveChallengeModal>
}

const mapDispatchToProps = dispatch => {
    return {
        createCompetition: (...args) => dispatch(createCompetitionAction(...args)),
    }
}

export default withPermissions(connect(null, mapDispatchToProps)(SolveChallenge));
const SolveChallengeModalConnected = withPermissions(connect(null, mapDispatchToProps)(SolveChallengeModal));
export { SolveChallengeModalConnected as SolveChallengeModal }
