// @flow

import React from 'react';
import { Layout } from 'antd';
import Header from './Header';
import styled from 'styled-components';
import ErrorBoundary from '../components/Common/ErrorBoundary';
import VerticalNav from './Menu/VerticalNav';
import Media from 'react-media';

const CustomLayout = styled(Layout)`
    background: rgb(245, 246, 245) !important;

    @media (min-width: 760px) {
        margin-left: 80px;
    }
`

type Props = {
    activeMenuItem: string,
    children: Object,
    breadcrumb?: Object,
}

const AppLayout = ({ activeMenuItem, children, breadcrumb = null }: Props) => (
    <div>
        <Media query="(min-width: 760px)">
            <VerticalNav activeMenuItem={activeMenuItem} />
        </Media>

        <CustomLayout>
            <Header />
            {breadcrumb ? breadcrumb : null}

            <ErrorBoundary>
                {children}
            </ErrorBoundary>
        </CustomLayout>
    </div>
);

export default AppLayout;
