// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Tooltip } from 'antd';
import { message, TextArea } from '../Ant';
import { hasPermission } from '../../helpers/HasPermission';
import { getUserPermissions, getCurrentUser } from '../../selectors';
import { User } from '../../types';
import { textToHtml } from '../../helpers';
import { searchClient } from '../Search/SearchInstance';
import PlaybookTitle from '../Playbook/PlaybookTitle';

type State = {
    visible: bool,
    reason: string,
}

type Props = {
    playbook: Object,
    update: Function,
    allPermissions: Array<string>,
    currentUser: User,
}

class ArchivePlaybook extends Component<Props, State> {

    constructor() {
        super();
        this.state = {
            visible: false,
            reason: '',
        }
        this.onConfirm = this.onConfirm.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.onReasonChange = this.onReasonChange.bind(this);
    }

    onConfirm: Function
    onConfirm() {
        let updatedPlaybook = JSON.parse(JSON.stringify(this.props.playbook));
        updatedPlaybook.attributes.is_archived = !updatedPlaybook.attributes.is_archived;

        if (updatedPlaybook.attributes.is_archived) {
            updatedPlaybook.attributes.archive_reason = this.state.reason.trim();
        }

        this.props.update(updatedPlaybook)
            .then(response => {
                searchClient.clearCache();

                this.hideModal();
                message.success(updatedPlaybook.attributes.is_archived ? 'Challenge has been archived' : 'Challenge has been un-archived');
            })
            .catch(error => message.error(error));
    }

    showModal: Function
    showModal() {
        this.setState({ visible: true });
    }

    hideModal: Function
    hideModal() {
        this.setState({ visible: false });
    }

    onReasonChange: Function
    onReasonChange(e: Object) {
        this.setState({ reason: e.target.value });
    }

    hasAccess() {
        if (hasPermission(this.props.allPermissions, 'can archive any task workflows')) {
            return true;
        }

        if (!this.props.playbook.relationships.uid || !this.props.playbook.relationships.uid.data) {
            return false;
        }

        let isPlaybookOwner = this.props.playbook.relationships.uid.data.id === this.props.currentUser.id;
        return isPlaybookOwner && hasPermission(this.props.allPermissions, 'can archive own task workflows');
    }

    render() {
        if (!this.hasAccess()) {
            return null;
        }

        let isArchived = this.props.playbook.attributes.is_archived;
        let archiveReason = this.props.playbook.attributes.archive_reason;
        return (
            <div>
                <div style={{ display: 'flex' }} className="hoverable archive-playbook" onClick={this.showModal}>
                    <Tooltip title={isArchived ? 'Archived' : 'Archive'}>
                        {this.props.children ?
                            this.props.children :
                            <span>{isArchived ? 'archived' : 'archive'}</span>
                        }
                    </Tooltip>
                </div>
                <Modal
                    title={
                        isArchived ?
                            <>`Unarchive <PlaybookTitle playbookId={this.props.playbook.id} />?</> :
                            <>Are you sure you want to archive <PlaybookTitle playbookId={this.props.playbook.id} />?</>
                        }
                    visible={this.state.visible}
                    onOk={this.onConfirm}
                    okText={isArchived ? 'Unarchive' : 'Archive'}
                    cancelText="Cancel"
                    onCancel={this.hideModal}
                    className="archive-workflow-modal"
                >
                    {isArchived ?
                        archiveReason ?
                            <div>
                                <p>This was archived for the following reason:</p>
                                <div dangerouslySetInnerHTML={{ __html: textToHtml(archiveReason) }} className="archive-reason"></div>
                            </div>
                            : <p>No reason was given for this being archived.</p>
                        :
                        <div>
                            <p>Add a reason (optional):</p>
                            <TextArea
                                value={this.state.reason}
                                onChange={this.onReasonChange}
                                autoSize
                            />
                        </div>
                    }
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        allPermissions: getUserPermissions(state, getCurrentUser(state)),
        currentUser: getCurrentUser(state),
    }
}

export default connect(mapStateToProps)(ArchivePlaybook);
export { ArchivePlaybook as _ArchivePlaybook }
