// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button } from '../Ant';
import { Card, Icon, Row, Col } from 'antd';
import { loadPublishedTaskTemplates } from '../../actions';
import { tokenise } from '../../helpers';

const StyledCard = styled(Card)`
    && {
        max-width: 300px;
        height: 100%;
        border-radius: 4px;

        @media (max-width: 600px) {
            margin: auto;
        }
    
        &:hover {
            background: #FFF;
            cursor: pointer;

            button {
                background: #FFF;
                color: #FFF;
                border: 1px solid ${props => props.theme.primaryColor};
            }
        }

        button {
            background: #FFF;
            color: ${props => props.selected ? "#444" : "inherit"};
            border: 1px solid rgb(217, 217, 217);
        }
    }

    .ant-card-head {
        border-bottom: none;
    }

    .ant-card-head-wrapper {
        border-bottom: 1px solid #e8e8e8;
    }

    .ant-card-head-title {
        justify-content: center;
        font-size: 18px;
    }
`

type Props = {
    taskTemplates: Array<Object>,
    onClick: Function,
    defaultSelected: string,
    loadTaskTemplates: Function,
}

type State = {
    selectedTaskTemplateId: string,
    isLoading: boolean,
}

class TaskTemplateGrid extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedTaskTemplateId: props.defaultSelected || '',
            isLoading: false,
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true })
        this.props.loadTaskTemplates()
            .then(result => {
                this.setState({ isLoading: false })
            });
    }

    render() {
        let { taskTemplates, onClick } = this.props;

        const content = <Row gutter={16} type="flex">
            {taskTemplates.map(template => {
                return (
                    <Col
                        sm={12}
                        md={8}
                        xl={8}
                        xxl={6}
                        key={template.id}
                        style={{ marginBottom: '16px' }}
                    >
                        <StyledCard
                            selected={this.state.selectedTaskTemplateId === template.id}
                            title={template.attributes.label}
                            onClick={() => {
                                this.setState({ selectedTaskTemplateId: template.id });
                                onClick(template);
                            }}
                        >
                            <p>{template.attributes.description}</p>

                            <Button style={{ width: '100%', marginTop: '40px' }} className={'ant-btn-' + tokenise(template.attributes.label)}>
                                {this.state.selectedTaskTemplateId === template.id ? <span><Icon type="check-circle" /> Selected</span> : 'Select'}
                            </Button>
                        </StyledCard>
                    </Col>
                )
            })}
        </Row>

        return typeof this.props.children === 'function' ?
            this.props.children(content, taskTemplates, this.state.isLoading) :
            content;
    }
};

const mapStateToProps = (state, props) => {
    return {
        taskTemplates: typeof state.jsonApi['task_template--task_template'] === 'undefined' ? [] :
            Object.values(state.jsonApi['task_template--task_template'])
                .filter((taskTemplate: Object) => taskTemplate.attributes.task_workflow_type === props.taskWorkflowTypeId)
                .filter((taskTemplate: Object) => taskTemplate.attributes.published === true),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadTaskTemplates: () => dispatch(loadPublishedTaskTemplates()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskTemplateGrid);
