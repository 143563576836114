// @flow

import * as React from 'react';
import styled from 'styled-components';
import FullWidthContentBlock from '../FullWidthContentBlock';
import { StagedMenuResponsive } from '../Kanban/KanbanMenu';
import columnDefinitions from '../Kanban/columnDefinitions';
import { Spacer } from '../Common';
import Padding from '../Common/Padding';

const HeaderWrapper = styled.div`
    background: #FFF;
`

const PlaybookHeadline = styled.span`
    font-size: 22px;
    font-weight: bold;
    width: 100%;
    
    @media (max-width: 700px) {
        text-align: center;
    }
`

const FlexWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: 670px) {
        flex-direction: column;
        align-items: center;
    }
`

const ActionsWrapper = styled.div`
    flex-shrink: 0;
    @media (max-width: 670px) {
        margin-top: 16px;
    }
`

type Props = {
    playbookId: string,
    mode: string,
    title: React.Node,
    actions: React.Node,
    tabs?: React.Node,
}

const PlaybookNav = ({
    playbookId,
    mode,
    title,
    actions,
    tabs
}: Props) => {
    return (
        <HeaderWrapper className="playbook-nav">
            <FullWidthContentBlock>
                <Padding size="small">
                    <FlexWrapper>
                        <div>
                            <StagedMenuResponsive
                                columns={columnDefinitions.map(definition => {
                                    return { ...definition, link: `/challenge/${playbookId}/${definition.id}` }
                                })}
                                activeColumn={mode}
                            />

                            <Spacer size="small" style={{ marginBottom: '0px' }}>
                                <PlaybookHeadline className="task-workflow-title">
                                    {title}
                                </PlaybookHeadline>
                            </Spacer>
                        </div>

                        <ActionsWrapper>
                            {actions}
                        </ActionsWrapper>
                    </FlexWrapper>

                </Padding>
            </FullWidthContentBlock>
            {tabs}
        </HeaderWrapper>
    );
};

export default PlaybookNav;
