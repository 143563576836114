// @flow

import React, { useState, useCallback } from 'react';
import type { Node } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { Button, Modal, message } from '../Ant';
import styled from 'styled-components';
import { createChallenge } from '../../actions';
import JsonApiRequestBuilder from '../../helpers/JsonApiRequestBuilder';
import TaskTemplateGrid from '../TaskTemplate/TaskTemplateGrid';
import ProgressWithTimer from '../ProgressWithTimer';
import { trackEvent } from '../../helpers/trackEvent';
import { hasPermission } from '../../helpers/HasPermission';
import { getUserPermissions, getCurrentUser } from '../../selectors';
import { searchClient } from '../Search/SearchInstance';
import { get } from '../../helpers';
import useTaskTemplates from '../../api/useTaskTemplates';
import { useContext } from 'react';
import { ApiContext } from '../../api/ApiContext';

const StepContentWrapper = styled.div`
    .ant-list-item {
        margin-bottom: 32px !important;
    }
    .ant-row {
        margin: 0;
    }
`

type Props = {
    createChallenge: Function,
    allPermissions: Array<string>,
    children?: Node,
    history: Object,
}

const CreateNewChallenge = ({ children, allPermissions, createChallenge, history }: Props) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedChallengeType, setSelectedChallengeType] = useState(null);
    const { taskTemplates, isLoading: isLoadingTemplates } = useTaskTemplates('challenge');

    const api = useContext(ApiContext);

    const shouldAutoCreate = useCallback(() => {
        return taskTemplates.length === 1;
    }, [taskTemplates])

    const showModal = useCallback(() => {
        if (isLoadingTemplates) {
            return null;
        }

        if (shouldAutoCreate()) {
            setSelectedChallengeType(taskTemplates[0]);
        }
        setIsVisible(true);
    }, [isLoadingTemplates, taskTemplates, setSelectedChallengeType, setIsVisible, shouldAutoCreate])

    const doCreateChallenge = () => {
        let playbookRequest = new JsonApiRequestBuilder('playbook--playbook');
        return api.post('/jsonapi/playbook/playbook', playbookRequest.build())
            .then(playbookResponse => {

                let request = new JsonApiRequestBuilder('task_workflow--challenge')
                    .attribute('title', ' ')
                    .relationship('task_template', 'task_template--task_template', selectedChallengeType.id);

                return api.post(`/jsonapi/task_workflow/challenge?playbookId=${playbookResponse.data.data.id}`, request.build())
                    .then(challengeResponse => {
                        trackEvent('challenge', 'Created new', 'Create Challenge Button');

                        return Promise.resolve({
                            playbook: playbookResponse.data,
                            challenge: playbookResponse.data,
                        })
                    })
            })
            .catch(error => {
                setIsLoading(false);
                console.log(error);
                message.warn('Something went wrong, please contact support if this error persists');
            })
    }

    if (!hasPermission(allPermissions, 'create challenge')) {
        return null;
    }

    return (
        <div>
            {typeof children === 'function' ?
                children(showModal) :
                <Button type="primary" onClick={showModal}>
                    Open
                </Button>
            }

            <Modal
                className="create-new-challenge-modal"
                title={'Creating your Opportunity'}
                visible={isVisible}
                onCancel={() => setIsVisible(false)}
                width={'100%'}
                footer={shouldAutoCreate() || isLoading ? [] : [
                    <Button type="primary" key="create" onClick={() => {
                        setIsLoading(true);
                    }}>Create Challenge</Button>,
                ]}
            >
                <StepContentWrapper>
                    {shouldAutoCreate() || isLoading ?
                        <ProgressWithTimer
                            hasStarted={true}
                            actionCallback={doCreateChallenge}
                            // @TODO, test creating multiple challenges.
                            // key={this.state.submitAttempt}
                            afterComplete={response => {
                                const playbookId = get(response, 'playbook.data.id');

                                if (playbookId) {
                                    setSelectedChallengeType(null);
                                    setIsVisible(false);
                                    
                                    history.push(`/challenge/${playbookId}/opportunity`);
                                    searchClient.clearCache();

                                    setIsLoading(false);
                                }
                            }}
                        >
                            progress
                        </ProgressWithTimer>
                        :
                        <TaskTemplateGrid
                            onClick={setSelectedChallengeType}
                            taskWorkflowTypeId={'challenge'}
                        />
                    }
                </StepContentWrapper>
            </Modal>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        allPermissions: getUserPermissions(state, getCurrentUser(state)),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createChallenge: (...args) => dispatch(createChallenge(...args)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateNewChallenge));
export { CreateNewChallenge as _CreateNewChallenge }
