import React, { useState } from "react"
import usePlaybook from "../../api/usePlaybook"
import { get, mergeEntityAttributes } from "../../helpers"
import { Input, Button, Spin } from "antd"
import useTaskWorkflow from "../../api/useTaskWorkflow"
import styled from 'styled-components';
import { message } from '../Ant';
import Spacer from '../Common/Spacer';

const PlaybookTitleWrapper = styled.div`
    @media (max-width: 671px) {
        Button {
            display: block;
            margin: 0.2em auto 0.5em;
        }
    }
`

const ResponsiveMarginAuto = styled.div`
    display: inline;

    @media (max-width: 671px) {
        margin: auto;
    }
`

const PlaybookTitleEdit = styled.div`
    .ant-input-group.ant-input-group-compact {
        display: flex;
        margin-top: 1px;
        margin-bottom: 3px;
    }

    @media (max-width: 759px) {
        .ant-input-group.ant-input-group-compact {
            flex-direction: column;
        }
        .ant-input-group.ant-input-group-compact input.ant-input {
            width: 100%;
            margin-bottom: 0.5em;
            border-radius: 4px;
        }
        .ant-input-group.ant-input-group-compact .ant-btn {
            border-radius: 4px;
        }
    }
`

const PlaybookTitle = ({ playbookId, emptyText = "", edit }) => {
    const { isLoading, workflows } = usePlaybook(playbookId)

    if (isLoading) {
        return <span>Loading...</span>
    }

    const challenge = workflows.find(
        (w) => w.type === "task_workflow--challenge"
    )
    const competition = workflows.find(
        (w) => w.type === "task_workflow--competition"
    )

    return (
        <PlaybookTitleFromWorkflows
            challenge={challenge}
            competition={competition}
            emptyText={emptyText}
            edit={edit}
        />
    )
}

const TitleButtonSpacer = ({ children }) => {
    return (
        <ResponsiveMarginAuto>
            <Spacer horizontal size='small' style={{display: "inline"}}>
                {children}
            </Spacer>
        </ResponsiveMarginAuto>
    )
}

const PlaybookTitleFromWorkflows = ({
    challenge,
    competition,
    emptyText = "",
    edit,
}) => {
    // Create State
    const [challengeDraftTitle, setChallengeDraftTitle] = useState("")
    const [competitionDraftTitle, setCompetitionDraftTitle] = useState("")
    const [isEditing, setIsEditing] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const { update: updateChallenge } = useTaskWorkflow(
        "challenge",
        challenge ? challenge.id : ""
    )

    const { update: updateCompetition } = useTaskWorkflow(
        "competition",
        competition ? competition.id : ""
    )

    let competitionTitle = get(competition, "attributes.title", "").trim()
    let challengeTitle = get(challenge, "attributes.title", "").trim()

    const handleSubmit = () => {
        setIsLoading(true)
        let promises = []
        if (competitionDraftTitle.length === 0) {
            message.warning('Competition Title Required')
        } else if (competitionDraftTitle !== competitionTitle) {
            const newComp = mergeEntityAttributes(
                competition,
                {
                    title: competitionDraftTitle,
                }
            )
            promises.push(updateCompetition(newComp))
        }

        if (challengeDraftTitle.length === 0) {
            message.warning('Challenge Title Required')
        } else if (challengeDraftTitle !== challengeTitle) {
            const newChal = mergeEntityAttributes(
                challenge,
                {
                    title: challengeDraftTitle,
                }
            )
            promises.push(updateChallenge(newChal))
        }

        Promise.all(promises)
        .then(res => {
            if (promises.length !== 0) {
                message.success('Title Updated')
            }
            setIsEditing(false)
            setIsLoading(false)
        })
        .catch(err => {
            message.warning('Update Failed')
            setIsEditing(false)
            setIsLoading(false)
        });
    }

    // Sets Spacer
    let spacer
    if (competitionTitle.length !== 0) {
        spacer = " - "
    } else {
        spacer = ""
    }

    // Empty String if no titles
    if (competitionTitle.length === 0 && challengeTitle.length === 0) {
        return <span>{emptyText}</span>
    }

    // If not editing
    if (!isEditing) {
        return (
            <span>
                <PlaybookTitleWrapper>
                    {competitionTitle}
                    {spacer}
                    {challengeTitle}
                    {edit ? (
                        <TitleButtonSpacer>
                            <Button
                                type="link"
                                icon="edit"
                                onClick={() => {
                                    // Get competition and challenge title
                                    setCompetitionDraftTitle(competitionTitle)
                                    setChallengeDraftTitle(challengeTitle)
                                    setIsEditing(true)
                                }}>
                            </Button>
                        </TitleButtonSpacer>
                    ) : null}
                </PlaybookTitleWrapper>
            </span>
        )
    }

    // If editing
    return (
        <span>
            <Spin spinning={isLoading}>
                <PlaybookTitleEdit>
                    <Input.Group compact >
                        <Input
                            className="comp"
                            value={competitionDraftTitle}
                            placeholder={"Competition title"}
                            onChange={(e) => {
                                setCompetitionDraftTitle(e.target.value)
                            }}
                        />

                        <Input
                            className="chal"
                            value={challengeDraftTitle}
                            placeholder={"Challenge title"}
                            onChange={(e) => {
                                setChallengeDraftTitle(e.target.value)
                            }}
                        />

                        <Button
                            onClick={handleSubmit}>
                            Save
                        </Button>
                    </Input.Group>
                </PlaybookTitleEdit>
            </Spin>
        </span>
    )
}

export default PlaybookTitle
export { PlaybookTitleFromWorkflows }
