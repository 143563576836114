import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { loadOrganisation } from '../../api/organisations';
import OrganisationLogo from './OrganisationLogo';
import { saveJsonApiResponse } from '../../api';
import { getOrganisation } from '../../selectors';
import { Skeleton } from 'antd';

const OrganisationLogoFromId = ({ organisationId, organisation, doSaveJsonApiResponse }) => {
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        // // No need to load anything.
        if (organisation || !organisationId) {
            return;
        }

        setIsLoading(true);
        loadOrganisation(organisationId)
            .then(result => {
                doSaveJsonApiResponse(result);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });

    }, [organisationId, organisation, doSaveJsonApiResponse])

    if (isLoading) {
        return <Skeleton active title={false} avatar paragraph={false} />
    }

    if (!organisation) {
        return null;
    }

    return (
        <OrganisationLogo
            maxWidth="75px"
            maxHeight="50px"
            organisation={organisation}
        />
    );
};

const mapStateToProps = (state, props) => {
    return {
        organisationId: props.organisationId,
        organisation: props.organisationId ? getOrganisation(state, props.organisationId) : null,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        doSaveJsonApiResponse: args => dispatch(saveJsonApiResponse(args)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationLogoFromId);
