import React from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';
import getConfig from '../../instance-config';
import { Link } from 'react-router-dom';

const LinkWithArrow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    position: relative;
    transition: all 0.5s ease;
    transition: .3s ease-in-out;

    a {
        color: ${props => props.theme.darkGrey};
        text-decoration: underline;
    }

    &:hover {
        transform: translateX(2px);
    }
`
const Arrow = styled.div`
    display: inline;
    margin-left: 8px;
`

const ArrowLink = ({ children, url }) => {
    return (
        <div>
            <LinkWithArrow>
                <Link to={url}>
                    {children}
                    <Arrow>
                        <Icon style={{ color: getConfig().primaryColor }} type="arrow-right" />
                    </Arrow>
                </Link>
            </LinkWithArrow>
        </div>
    )
}

export default ArrowLink;