// @flow

import React from 'react';
import styled from 'styled-components';
import { TaskWorkflow } from '../../types';
import CollaboratorsTeaser from '../CollaboratorsTeaser';
import { textTrim } from '../../helpers';

const Wrapper = styled.div`
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 16px;
    height: 110px;
    width: 248px;
    max-width: 100%;
    box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.1);
    background: #FFF;
    transition: box-shadow 150ms ease-in-out;
    word-break: break-word;

    &:hover {
        box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.3);
    }
`

const FlexWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`

type Props = {
    workflow: TaskWorkflow,
}

const KanbanOpportunityTile = ({ workflow }: Props) => {
    return (
        <Wrapper style={{ ...(workflow.ghost ? { opacity: 0.5 } : {}) }}>
            <FlexWrapper>
                <div>
                    <div
                        style={{
                            color: `${props => props.theme.greyBlack}`,
                            fontWeight: 500,
                        }}
                    >
                        {textTrim(workflow.attributes.title, 50)}
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <CollaboratorsTeaser
                        playbookId={workflow.attributes.playbook_id}
                        size="small"
                        hideOwner={false}
                    />
                </div>
            </FlexWrapper>
        </Wrapper>
    );
};

export default KanbanOpportunityTile;
