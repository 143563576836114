import React from 'react';
import { connect } from 'react-redux';
import { connectHits } from 'react-instantsearch-dom';
import { getTaskWorkflow } from "../../selectors";
import { saveJsonApiResponse, extractErrorMessage } from "../../api";
import { useEffect, useCallback } from 'react';
import { jsonApi } from '../../actions';
import qs from 'qs';
import { message } from 'antd';
import axios from 'axios';

const AlgoliaWorkflowHitsLoader = ({ hits, hitsNeedLoading, workflows, workflowTypeId, doSaveJsonApiResponse, children, workflowsToHide }) => {

    // There is a known issue here where algolia remounts the component multiple times
    // which causes the requests to be cancelled a few times.
    useEffect(() => {
        if (hitsNeedLoading.length === 0) {
            return;
        }

        let query = {
            'filter[id][condition][path]': 'id',
            'filter[id][condition][value]': [],
            'filter[id][condition][operator]': 'IN',
            'include': [
                'task_workflow_type',
                'task_template',
                'tags',
                'image',
            ].join(','),
        };
        hitsNeedLoading.forEach(hit => {
            query['filter[id][condition][value]'].push(hit.uuid);
        })
        let queryString = qs.stringify(query);
        const source = axios.CancelToken.source();

        jsonApi()
            .get(`/jsonapi/task_workflow/${workflowTypeId}?${queryString}`, {
                cancelToken: source.token,
            })
            .then(result => {
                doSaveJsonApiResponse(result);
            })
            .catch(error => {
                if (!axios.isCancel(error)) {
                    message.warning(extractErrorMessage(error));
                }
            })

        return () => {
            source.cancel();
        }

    }, [hitsNeedLoading, doSaveJsonApiResponse, workflowTypeId]);

    const buildDataSourceFromHits = useCallback(() => {
        return workflows
            .filter(w => !workflowsToHide.find(p => p.id === w.id))
            .concat(hitsNeedLoading.map(hit => {
                return {
                    isLoading: true,
                    id: hit.uuid,
                    attributes: {
                        title: hit.title,
                        created: hit.created,
                        organisation_id: hit.organisation_id,
                        is_pinned: hit.is_pinned,
                    }
                }
            }))
    }, [hitsNeedLoading, workflows, workflowsToHide]);

    return children(buildDataSourceFromHits())
}

const mapStateToProps = (state, props) => {
    const hitsNeedLoading = props.hits
        .filter(hit => !getTaskWorkflow(state, props.workflowTypeId, hit.uuid));
    const workflows = props.hits
        .map(hit => getTaskWorkflow(state, props.workflowTypeId, hit.uuid))
        .filter(workflow => workflow !== null);

    return {
        hitsNeedLoading,
        workflows,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        doSaveJsonApiResponse: (...args) => dispatch(saveJsonApiResponse(...args))
    }
}

// Prevent the component rerendering unless the hitsNeedLoading changes.
const AlgoliaWorkflowHitsLoaderCached = React.memo(AlgoliaWorkflowHitsLoader, (prevProps, nextProps) => {
    if (prevProps.isShowingGrid !== nextProps.isShowingGrid) {
        return false;
    }

    // Always render if the number of hits has changed or the number to hide.
    if (prevProps.hits.length !== nextProps.hits.length || prevProps.workflowsToHide.length !== nextProps.workflowsToHide.length) {
        return false;
    }

    // Always render if the hits that need loading has changed.
    if (prevProps.hitsNeedLoading.length !== nextProps.hitsNeedLoading.length) {
        return false;
    }

    // If we have the same number of hits but they've changed, then check.
    for (let i = 0; i < nextProps.hitsNeedLoading.length; i++) {
        if (nextProps.hitsNeedLoading[i].objectID !== prevProps.hitsNeedLoading[i].objectID) {
            return false;
        }
    }

    // The hits from algolia have changed.
    for (let i = 0; i < nextProps.hits.length; i++) {
        if (nextProps.hits[i].objectID !== prevProps.hits[i].objectID) {
            return false;
        }
    }

    // The hits to hide have changed.
    for (let i = 0; i < nextProps.workflowsToHide.length; i++) {
        if (nextProps.workflowsToHide[i].id !== prevProps.workflowsToHide[i].id) {
            return false;
        }
    }

    return true;
});

const ConnectedHits = connect(mapStateToProps, mapDispatchToProps)(AlgoliaWorkflowHitsLoaderCached);
export default connectHits(ConnectedHits);
