// @flow

import React, { useState } from 'react';
import { Modal, Button } from '../Ant';
import PublishCompetitionForm from '../../Forms/PublishCompetitionForm';
import { TaskWorkflow } from '../../types';

type Props = {
    competition: TaskWorkflow,
    update: Function,
    children?: Function,
}

const LaunchCompetitionPublishModal = ({ competition, update, children }: Props) => {
    const [isVisible, setIsVisible] = useState(false);
    const [overrideCompetition, setCompetition] = useState(null);

    return (
        <div>
            {typeof children === 'function' ?
                children((overrideComp) => {
                    setIsVisible(true);
                    // Workaround to support kanban, may need a re-think.
                    if (overrideComp) {
                        setCompetition(overrideComp);
                    }
                })
                : <Button className="launch-competition" type="primary" onClick={() => setIsVisible(true)}>Publish to Unearthed</Button>
            }

            <Modal
                title={'Publish Competition'}
                titleIntro={'You are publishing this competition to Unearthed'}
                visible={isVisible}
                onCancel={() => setIsVisible(false)}
                width={'100%'}
                footer={[]}
                className="launch-competition-modal"
            >
                {overrideCompetition || competition ?
                    <PublishCompetitionForm
                        competition={overrideCompetition || competition}
                        update={update}
                    />
                    : null}
            </Modal>
        </div>
    );
};

export default LaunchCompetitionPublishModal;