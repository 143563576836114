// @flow

import React from 'react';
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components';
import { Spin, Icon, Progress } from 'antd';

const UploadWrapper = styled.div`
    background-color: #F4F4F4;
    border-radius: 3px;
    text-align: center;
    padding: 30px;
    border: 1px dashed ${props => props.isDragReject ? 'red' : '#D9D9D9'};

    &:hover {
        cursor: pointer;
    }
`

type Props = {
    handleUpload: Function,
    uploadInstructions?: string,
    uploadProgress: number,
    uploadInProgress: bool,
    options?: Object,
}

const UploadDropArea = ({ onDrop, handleUpload, uploadProgress, uploadInProgress, uploadInstructions, options = {} }: Props) => {
    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        onDrop: handleUpload,
        ...options,
    });

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />

            <Spin spinning={uploadInProgress}>
                <UploadWrapper isDragReject={isDragReject}>
                    <div>
                        <Icon style={{ fontSize: '30px', marginBottom: '20px', color: `${props => props.theme.primaryColor}` }} type="upload" />
                        {isDragActive ?
                            <h3>Drop the files here</h3> :
                            <h3>Click or drag file to this area to upload</h3>
                        }
                        <p>{uploadInstructions}</p>
                    </div>
                    {uploadInProgress ? <Progress percent={uploadProgress} size="small" /> : null}
                </UploadWrapper>
            </Spin>

        </div>
    );
};

UploadDropArea.defaultProps = {
    uploadInstructions: 'Supports single or bulk file upload. Max size 20MB.',
}

export default UploadDropArea;
