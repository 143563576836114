// @flow

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SubmissionTeaser from './SubmissionTeaser';
import getBaseUrl from '../Unearthed/getBaseUrl';
import normalize from 'json-api-normalizer';
import { getRelationship } from '../../selectors';
import { Spin } from 'antd';
import encodeObjectToQueryString from '../../helpers/encodeObjectToQueryString';
import { http } from '../../actions';
import axios from 'axios';
import { TaskWorkflow } from '../../types';
import { Link } from 'react-router-dom';

const SubmissionGrid = styled.div`
    width: 100%;
`

type Props = {
    competition: TaskWorkflow,
    limit: number,
    children?: Function,
}

const SubmissionList = ({ competition, children, limit = 50 }: Props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [submissions, setSubmissions] = useState([]);
    const [responseData, setResponseData] = useState({ jsonApi: {} });

    useEffect(() => {
        let endpoint = '/jsonapi/submission/hackathon';
        let options = {
            include: 'field_team,field_images,field_private_files',
            'filter[field_competition.id]': competition.attributes.remote_uuid,
            consumerId: 'a4767485-cf86-4782-b836-7fafb59dde8b',
            'page[limit]': limit,
            // Replicate common access control from the server.
            'filter[title][condition][path]': 'field_title',
            'filter[title][condition][value]': '',
            'filter[title][condition][operator]': '<>',
        }

        const source = axios.CancelToken.source();
        let url = endpoint + '?' + encodeObjectToQueryString(options);
        http().get('/api/unearthed/portal-proxy?u=' + encodeURIComponent(url), {
            cancelToken: source.token,
        })
            .then(response => {
                let normalized = { jsonApi: normalize(response.data, { camelizeKeys: false, camelizeTypeValues: false }) };
                setResponseData(normalized);
                setSubmissions(response.data.data);
                setIsLoading(false);
            })
            .catch(error => {
                // Do nothing for cancelled requests.
                if (!axios.isCancel(error)) {
                    setIsLoading(false);
                    console.log(error);
                }
            })

        return () => {
            source.cancel();
        }
    }, [competition.attributes.remote_uuid, limit])

    if (!isLoading && submissions.length === 0) {
        return <p>There are currently no submissions.</p>
    }

    const submissionElements = <Spin spinning={isLoading}>
        <SubmissionGrid>
            {submissions.map((submission, i) => {
                return (
                    <Link key={i} to={'/submission/hackathon/' + submission.id}>
                        <SubmissionTeaser
                            submission={submission}
                            baseHost={getBaseUrl()}
                            onTeaserClick={() => { }}
                            team={getRelationship(responseData, 'field_team', submission)}
                            images={getRelationship(responseData, 'field_images', submission)}
                        />
                    </Link>
                )
            })}
        </SubmissionGrid>
    </Spin>

    if (typeof children === 'function') {
        return children(submissions, submissionElements, isLoading);
    }

    return submissionElements;
};

export default SubmissionList;
