import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import PinnedWorkflowList from '../components/TaskWorkflow/PinnedWorkflowList';
import styled from 'styled-components';
import Padding from '../components/Common/Padding';
import AlgoliaWorkflowList from '../components/Algolia/AlgoliaWorkflowList';
import columns from '../components/Kanban/columnDefinitions';
import OpportunityGrid from '../components/Kanban/OpportunityGrid';
import PinnableItem from '../components/Kanban/PinnableItem';
import usePinnedWorkflows from '../components/Kanban/usePinnedWorkflows';
import KanbanMenu, { KanbanMenuSingleColumn } from '../components/Kanban/KanbanMenu';
import { Spin } from 'antd';
import filterBuilder from '../components/Algolia/filterBuilder';
import { useSetPageTitle } from '../hoc/setPageTitle';
import Media from 'react-media';

const KanbanOuterWraper = styled.div`
    max-width: 100%;
    overflow: scroll;
`
const KanbanWrapper = styled.div`
    width: 1750px;
    margin: 0 auto;

    @media (max-width: 1750px) {
        width: auto;
    }
`

const Wrapper = styled.div`
    border: 1px solid #C4C4C4;
    border-radius: 4px;
`

const KanbanColumnList = ({ match }) => {
    const column = columns.find(c => c.id === match.params.columnId);
    const { isLoading, workflows: pinnedWorkflows, replaceWorkflow, swapWorkflows } = usePinnedWorkflows(column.id);
    useSetPageTitle(`Kanban ${column.id}`);

    const getAlgoliaFilter = useCallback(() => {
        return filterBuilder([
            {
                name: 'workflow_status',
                value: column.workflow_status,
            },
            {
                name: 'algolia_is_new',
                value: '0',
            }
        ])
    }, [column.workflow_status])

    return (
        <KanbanOuterWraper>
            <KanbanWrapper>
                <Padding horizontal>
                    <Media query="(max-width: 1025px)">
                        <KanbanMenuSingleColumn
                            columns={columns}
                            activeColumn={column.id}
                        />
                    </Media>

                    <Media query="(min-width: 1026px)">
                        <KanbanMenu
                            columns={columns}
                            activeColumn={column.id}
                        />
                    </Media>

                    <Wrapper>
                        <Spin spinning={isLoading}>
                            <PinnedWorkflowList
                                column={column}
                                // Prevent passing old data through if we are reloading.
                                workflows={isLoading ? [] : pinnedWorkflows}
                                replaceWorkflow={replaceWorkflow}
                                swapWorkflows={swapWorkflows}
                            />
                        </Spin>

                        <Padding
                            className="column-list-all"
                            horizontal
                            style={{ background: '#FFF' }}
                        >
                            <AlgoliaWorkflowList
                                workflowTypeId={column.id === 'opportunity' ? 'challenge' : 'competition'}
                                workflowsToHide={pinnedWorkflows}
                                algoliaFilterExtra={getAlgoliaFilter()}
                            >
                                {workflows => {
                                    return <OpportunityGrid>
                                        {workflows
                                            .map(workflow => {
                                                return (
                                                    <PinnableItem
                                                        id={workflow.id}
                                                        key={workflow.id}
                                                        workflow={workflow}
                                                        column={column.id}
                                                    >
                                                        {workflow.isLoading ? null : column.render(workflow)}
                                                    </PinnableItem>
                                                )
                                            })}
                                    </OpportunityGrid>
                                }}
                            </AlgoliaWorkflowList>
                        </Padding>
                    </Wrapper>
                </Padding>
            </KanbanWrapper>
        </KanbanOuterWraper>
    );
};

export default withRouter(KanbanColumnList);
