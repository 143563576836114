import React, { useState, useCallback } from 'react';
import { Form, Spin, message } from 'antd';
import { extractErrorMessage } from '../api';
import { mergeEntityAttributes } from '../helpers';
import { Input, Select, Button } from '../components/Ant';
import FormActions from '../components/Form/FormActions';
import { updateMetricType, createMetricType, deleteMetricType } from '../api/metrics';

const EditMetricTypeForm = ({ form, metricType, onAfterSave }) => {
    const [isLoading, setIsLoading] = useState(false);

    const errorHandler = useCallback(error => {
        console.log(error);
        setIsLoading(false);
        message.error(extractErrorMessage(error));
    }, []);

    const successHandler = useCallback((text, result) => {
        setIsLoading(false);
        message.success(text);

        if (typeof onAfterSave === 'function') {
            onAfterSave(result.data.data);
        }
    }, [onAfterSave])

    const handleDelete = useCallback(() => {
        setIsLoading(true);
        deleteMetricType(metricType)
            .then(response => {
                if (typeof onAfterSave === 'function') {
                    onAfterSave(response.data.data);
                }
            })
            .catch(errorHandler);
    }, [metricType, errorHandler, onAfterSave])

    const onSave = useCallback(e => {
        e.preventDefault();

        form.validateFields()
            .then(result => {
                setIsLoading(true);
                const newAttributes = {
                    name: result.name,
                    unit: result.unit,
                }
                if (metricType.id) {
                    let updated = mergeEntityAttributes(metricType, newAttributes);

                    updateMetricType(updated)
                        .then(response => successHandler('Metric updated', response))
                        .catch(errorHandler);
                }
                else {
                    createMetricType(newAttributes.name, newAttributes.unit)
                        .then(response => {
                            form.resetFields();

                            // We have to wait a second becase Drupal takes a while to register the new route.
                            setTimeout(() => {
                                successHandler('Metric created', response)
                            }, 2000);
                        })
                        .catch(errorHandler);
                }
            })

    }, [metricType, form, errorHandler, successHandler]);

    const getMetricUnits = useCallback(() => {
        return [
            'Litres',
            'Metres',
            'AUD',
        ];
    }, [])

    return (
        <Spin spinning={isLoading}>
            <Form onSubmit={onSave}>
                <Form.Item label="Name">
                    {form.getFieldDecorator('name', {
                        rules: [{ required: true, message: 'Please input the metric name.' }],
                        initialValue: metricType.attributes.name,
                    })(
                        <Input name="label" autoFocus />
                    )}
                </Form.Item>

                <Form.Item label="Unit">
                    {form.getFieldDecorator('unit', {
                        rules: [{ required: true, message: 'Please select the metric unit.' }],
                        initialValue: metricType.attributes.unit,
                    })(
                        <Select>
                            {getMetricUnits().map(unit => <Select.Option key={unit} name={unit} value={unit}>{unit}</Select.Option>)}
                        </Select>
                    )}
                </Form.Item>

                <FormActions>
                    <Button onClick={handleDelete}>Delete</Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >Save</Button>
                </FormActions>
            </Form>
        </Spin>
    );
};

export default Form.create({ name: 'edit_metric_type' })(EditMetricTypeForm);
